import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import router from './router/index'
import "@/fonts/jxzk.css"
import '@/assets/style/index.scss' // global css
import VueCropper from 'vue-cropper'

const app = createApp(App);

app.use(router)
app.use(VueCropper)
app.use(ElementPlus);

app.mount("#app");
