<template>
  <!-- <img alt="background" src="./assets/background.png"> -->
  <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  <router-view />
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// import home from './components/home.vue'

export default {
  name: 'App',
  components: {
    // HelloWorld,
  }
}
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  font-family: jiangxizhuokai;
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
